<!-- job_fair -->
<template>
  <div class="oldPosition">
    <header>
      <Button size="small" type="warning" @click="$router.back()">
        <Icon type="ios-arrow-back" />
          返回
      </Button>
      <Divider type="vertical" />
      <p>{{this.$route.meta.title}}</p>
    </header>

    <section>
      <Table :loading="loading" border ref="selection" :columns="columns" :data="Tabledata" @on-selection-change="handleSelectRow()">
        <template slot-scope="{ row }" slot="details">
          <Button type="primary" size="small" @click="getDetail(row._id)">查看详情</Button>
        </template>
      </Table>

      <div class="footer">
        <Button type="warning" style="width:146px;margin-top:20px;" @click="addOldPosition">导入历史职位</Button>
      </div>
    </section>

    <!-- ***************************************查看历史职位详情 -->
    <Modal
                v-model="modal2"
                title="历史职位详情"
                width="540"
                @on-ok="positionOk"
                >
                <div class="list_items">
                  <div class="list_item_left"><span style="font-size: 14px;color:#F56C6C;">*</span>职位名称</div>
                  <div class="list_item_right">
                    <input type="text" v-model="p_name" value="" readonly />
                  </div>
                </div>
                <div class="list_items">
                  <div class="list_item_left"><span style="font-size: 14px;color:#F56C6C;">*</span>招聘人数</div>
                  <div class="list_item_right" style="position:relative;">
                    <input type="text" v-model="p_number" value="" readonly />
                    <div style="position:absolute;right:0;top:10px;">人</div>
                  </div>
                </div>
                <div class="list_items">
                  <div class="list_item_left"><span style="font-size: 14px;color:#F56C6C;">*</span>薪资</div>
                  <div class="list_item_right" style="position:relative;">
                    <input type="text" v-model="p_pay" value="" readonly />
                    <div style="position:absolute;right:0;top:10px;">元/月</div>
                  </div>
                </div>
                <div class="list_items">
                  <div class="list_item_left"><span style="font-size: 14px;color:#F56C6C;">*</span>职位要求一</div>
                  <div class="list_item_right">
                    <input type="text" v-model="p_askfor_one" value="" readonly />
                  </div>
                </div>
                <div class="list_items">
                  <div class="list_item_left"><span style="font-size: 14px;color:#F56C6C;">*</span>职位要求二</div>
                  <div class="list_item_right">
                    <input type="text" v-model="p_askfor_two" value="" readonly />
                  </div>
                </div>
                <div class="list_items">
                  <div class="list_item_left">职位要求三</div>
                  <div class="list_item_right">
                    <input type="text" v-model="p_askfor_three" value="" readonly />
                  </div>
                </div>
                <div class="list_items">
                  <div class="list_item_left">职位要求四</div>
                  <div class="list_item_right">
                    <input type="text" v-model="p_askfor_four" value="" readonly />
                  </div>
                </div>
            </Modal>



  </div>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      mobile:'',
      Tabledata: [],//表格数据
      columns: [
        {
          type: 'selection',
          width: 40,
          align: 'center',
        },
        {
          title: '职位名称',
          key: 'name',
        },
        {
          title: '招聘人数',
          key: 'number',
        },
        {
          title: '薪资',
          key: 'pay',
        },
        {
          title: '操作',
          slot:'details'
        },

      ],

      modal2:false,
      p_name:'',
      p_number:'',
      p_pay:'',
      p_askfor_one:'',
      p_askfor_two:'',
      p_askfor_three:'',
      p_askfor_four:'',

      list:[]
      

    }
  },
  methods: {
    
    // ************************************招聘职位
    positionOk() {
      this.modal2 = false
    },

    // **************************************职位详情
    getDetail(id){
      this.modal2 = true;
      console.log(id)
      this.app("enterpriseOperators", "importJobFairPositionDetail_v3", {
        _id: id,
      }).then(res => {
        console.log(res)
        this.p_name = res.data.name
        this.p_number = res.data.number
        this.p_pay = res.data.pay
        this.p_askfor_one = res.data.askfor_one
        this.p_askfor_two = res.data.askfor_two
        this.p_askfor_three = res.data.askfor_three
        this.p_askfor_four = res.data.askfor_four
      })
    },

    // *******************************************选择职位

    handleSelectRow(selection, index) {
      console.log(this.$refs.selection.getSelection());
      this.list = this.$refs.selection.getSelection();
    },

    out() {
      localStorage.removeItem("firm_mobile")
      localStorage.removeItem("firm_token")
      localStorage.removeItem("firm_enterpriseId")
      this.$router.push('/login')
    },

    // *************************************table数据
    get_data(){
       // 表格数据
       this.loading = true
       this.app("enterpriseOperators", "importJobFairPositions_v3", {
              mobile: this.mobile,
            }).then(res => {
              console.log(res)
              this.loading = false
              if(res.data.length > 0){
                this.Tabledata = res.data
              }else{
                this.$Modal.warning({
                  title:'暂无历史职位可以导入！',
                });
              }
              
            })
    },

    addOldPosition(){
      var listss = this.list;
                            var positions = localStorage.getItem('positions');

                              if(positions.length > 0){
                                var result = JSON.parse(localStorage.getItem('positions'));
                              }else{
                                var result = [];
                              }
                              
                              console.log(result);



                              listss.forEach(function(value,index,array){
                                console.log(value,index,array);
                                
                          
                                  var position = {
                                    name: array[index].name,
                                    number: array[index].number,
                                    pay: array[index].pay,
                                    askfor_one: array[index].askfor_one,
                                    askfor_two: array[index].askfor_two,
                                    askfor_three: array[index].askfor_three,
                                    askfor_four: array[index].askfor_four
                                  }
                                  result.push(position);

                                

                              });

                              console.log(result);
                              
                              localStorage.setItem('positions', JSON.stringify(result))
                             
                              // layer.open({
                              //   // title: '提示',
                              //   content: '导入历史职位成功！',
                              //   yes:function(){
                              //     window.history.back();
                              //   }
                              // })
                              this.$Message.success('导入历史职位成功！');

                              // setTimeout(function(){
                              this.$router.back()
                              // },1500)

      
    }

    
  },

  created() {
    this.mobile = localStorage.getItem("firm_mobile");
    console.log(this.mobile)
    this.get_data();
  }
}
</script>

<style lang="less" scoped>
  header{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .list_items{
      justify-content: center;
      margin-bottom:20px;
      display:flex;
      align-items: center;
    }
    .list_item_left{
      width:100px;
      height:40px;
      line-height:40px;
      text-align: left;
      font-size:14px;
      color: #606266;
      margin-right:12px;
    }
    .list_item_right{
      width:410px;
    }
    .list_item_right input{
      width: 410px;
      height: 40px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #e4e7ed;
      border-radius: 4px;
      padding-left:12px;
    }

    .footer{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top:18px;
      height: 35px;
    }

</style>
